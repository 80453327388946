import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// project import
import DashboardLayout from 'layout/Dashboard';
import ErrorBoundary from './ErrorBoundary';
import Loadable from 'components/Loadable';
import ProtectedRoutes from 'utils/route-guard/ProtectedRoutes';

// render
const Dashboard = Loadable(lazy(() => import('pages/dashboard')));
const ContactUs = Loadable(lazy(() => import('pages/others/contact-us')));
const Correlation = Loadable(lazy(() => import('pages/others/correlation')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <ProtectedRoutes />,
  children: [
    {
      path: '/',
      element: <DashboardLayout />,
      errorElement: <ErrorBoundary />,
      children: [
        {
          index: true,
          element: <Dashboard />
        },
        {
          path: '/dashboard',
          element: <Dashboard />
        },
        {
          path: '/correlation',
          element: <Correlation />
        },
        {
          path: '/contact-us',
          element: <ContactUs />
        },
        {
          path: '*',
          element: <Navigate to="/error/404" />
        }
      ]
    }
  ]
};

export default MainRoutes;
